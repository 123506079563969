import React from 'react'

class Footer extends React.Component {
  render() {
    return (
      <section id="footer" className="footer special">
        &copy; Chelsea Williams 2019
      </section>
    )
  }
}

export default Footer
